import { SVGProps } from 'react';

function Twitter({
  fill = 'var(--neutral-0)',
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1303 10.5158L21.162 2.30811H19.4957L13.3901 9.43476L8.51348 2.30811H2.88892L10.2633 13.0849L2.88892 21.6919H4.55531L11.0031 14.1659L16.1531 21.6919H21.7777L14.1303 10.5158ZM11.8479 13.1798L11.1008 12.1067L5.15574 3.56774H7.71523L12.5129 10.459L13.2601 11.5321L19.4965 20.4896H16.937L11.8479 13.1798Z"
        fill={fill}
      />
    </svg>
  );
}

export default Twitter;
